import './styles/style.scss';

import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2F5bmUtdHciLCJhIjoiY20zZXA5dTRhMGNxMTJzc2FzaHUzMHBsbSJ9.7oOLc8UyLpshfOd-AcW5wg';
const map = new mapboxgl.Map({
  container: 'map', // container ID
  style: 'mapbox://styles/mapbox/streets-v12', // style URL
  center: [120.9738819, 23.97565], // starting position [lng, lat]
  zoom: 9, // starting zoom
});

// map.addControl(new mapboxgl.NavigationControl(), 'top-right');

// // 添加定位控制項
// map.addControl(
//   new mapboxgl.GeolocateControl({
//     positionOptions: {
//       enableHighAccuracy: true,
//     },
//     trackUserLocation: true,
//     showUserHeading: true,
//   }),
// );
